import {isMatch} from 'lodash'

class eventsPlugin {
  static install(Vue, options) {
    Vue.prototype.$filtered_on = function (event, filter, callback) {
      const handler = function(data) {
        if (isMatch(data, filter)) {
          callback(data)
        }
      }

      this.$on(event,handler)
    }

    Vue.prototype.$filtered_once = function (event, filter, callback) {
      const handler = function(data) {
        if (isMatch(data, filter)) {
          this.$off(event, handler)
          callback(data)
        }
      }

      this.$on(event,handler)
    }
  }
}

export default eventsPlugin
