import _ from 'lodash'

class arrayPlugin {
  static install(Vue, options) {
    Vue.prototype.array_insert = function(array, value) {
      const value_id = value.id || value
      const idx = _.findIndex(array, (e) => {
        return e.id == value_id || e == value_id
      })
    
      if (idx != -1) {
        array.splice(idx, 1, value)
      } else {
        array.push(value)        
      }
    }

    Vue.prototype.array_remove = function(array, value) {
      const value_id = value.id || value
      const idx = _.findIndex(array, (e) => {
        return e.id == value_id || e == value_id
      })
    
      if (idx != -1) {
        array.splice(idx, 1)
      }
    }

    Vue.prototype.array_merge = function(array, values) {
      const self = this
      
      _.each(values, (v) => {
        self.array_insert(array, v)
      })
    }

    Vue.prototype.array_replace = function(oldarray, newarray) {
      const self = this

      const old_ids = _.map(oldarray, (e) => {return e.id || e})
      const new_ids = _.map(newarray, (e) => {return e.id || e})

      const to_remove = _.difference(old_ids, new_ids)
      _.each(to_remove, (v) => {
        self.array_remove(oldarray, v)
      })

      _.each(newarray, (v) => {
        self.array_insert(oldarray, v)
      })
    }

  }
}

export default arrayPlugin
