import Vue from 'vue/dist/vue.esm'
import {upperFirst, camelCase, kebabCase} from 'lodash'

const requireComponent = require.context('../components', true, /\.(vue|js)$/)

const requireDirective = require.context('../directives', true, /\.(js)$/)

const requirePlugin = require.context('../plugins', true, /\.(js)$/)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = camelCase(fileName.split('/').pop().replace(/\.\w+$/, ''))

  console.log(componentName)

  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  )
})

requireDirective.keys().forEach(fileName => {
  const directiveConfig = requireDirective(fileName)

  const directiveName = kebabCase(fileName.split('/').pop().replace(/\.\w+$/, ''))

  Vue.directive(
    directiveName,
    directiveConfig.default || directiveConfig
  )
})

requirePlugin.keys().forEach(fileName => {
  const plugin = requirePlugin(fileName)

  Vue.use(plugin.default || plugin)
})

