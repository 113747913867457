import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'

Vue.use(Vuex)

const initStore = () => new Vuex.Store({
  state: {
  },
  getters: {
    getDict (state, getters) {
      return function ({name, key}) {
        if (!state[name]) {
          return undefined
        }
        else {
          return state[name][key]   
        }
      }
    },
  },
  mutations: {
    setDict (state, {name, key, value}) {
      if (!state[name]) {
        Vue.set(state, name, {})
      }
      Vue.set(state[name], key, value)   
    }
  }
})

export default initStore
