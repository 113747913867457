import Bus from '../services/bus'


export default {
  data: function () {
    return {
      bus: Bus
    }
  }
}
