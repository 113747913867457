
window.jQuery = $
window.$ = $

import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import initStore from '../store'

import 'normalize.css'
import 'assets/styles'
import 'bootstrap'


require('../helpers/vueRegister')
require.context('../assets/images', true)

Vue.use(TurbolinksAdapter)

Vue.mixin(require('../mixins/busMixin').default)

document.addEventListener('turbolinks:load', () => {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })
  const app = new Vue({
    el: "#mainapp",
    store: initStore(),
    turbolinksDestroyEvent: 'turbolinks:request-end',
    methods: {
      showId(id) {
        this.$store.commit('setDict', {name: 'visibles', key: id, value: true})
      },
      hideId(id) {
        this.$store.commit('setDict', {name: 'visibles', key: id, value: false})
      },
      toggleId(id) {
        this.$store.commit('setDict', {name: 'visibles', key: id, value: !this.isVisible(id)})
      },
      isVisible(id) {
        return this.$store.getters.getDict({name: 'visibles', key: id})
      }
    }
  })
   

})
