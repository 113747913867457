import axios from 'axios'
const qs = require('qs')

const csrfToken = document.querySelector("meta[name=csrf-token]")
if (csrfToken) {
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken.content
}

axios.defaults.paramsSerializer = params => {
  return qs.stringify(params, {arrayFormat: 'brackets'});
};

class WAxios {
  static install(Vue, options) {
    Vue.prototype.$axios = axios
  }
}

export default WAxios
