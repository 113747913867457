import {reduce, has, toPath} from 'lodash'

class deepObjectPlugin {
  static install(Vue, options) {
    Vue.deepObject = function(object, path) {
      return reduce(toPath(path), (obj, p) => {
        if (!has(obj, p)) {
          Vue.set(obj, p, {})
        }
        return obj[p]
      }, object)
    }

    Vue.deepSet = function(object, path, value) {
      const full_path = toPath(path)
      const last_key = full_path.pop()

      Vue.set(Vue.deepObject(object, full_path), last_key, value)
    },

    Vue.deepGet = function(object, path) {
      const full_path = toPath(path)
      const last_key = full_path.pop()

      return Vue.deepObject(object, full_path)[last_key]
    },

    Vue.deepDelete = function(object, path) {
      const full_path = toPath(path)
      const last_key = full_path.pop()

      Vue.delete(Vue.deepObject(object, full_path), last_key)
    }

  }
}

export default deepObjectPlugin
